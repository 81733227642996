<template>
  <b-card>
    <finance-table></finance-table>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import FinanceTable from '@/components/FinanceTable.vue'

export default {
  components: {
    BCard,
    BCardText,
    FinanceTable
  },
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style>

</style>
