<template>
  <b-row>
    <b-col
      xs="3">
      <select class="form-control input-sm" v-model="myValue.key">
        <option v-for="option in options.keys" :key="option.id" :value="option.id">
          {{option.name}}
        </option>
      </select>
      <!-- <v-select
        v-model="key"
        dir="ltr"
        label="name"
        :options="options.keys"
      /> -->
    </b-col>
    <b-col
      xs="3">
      <select class="form-control input-sm" v-model="myValue.operator">
        <option v-for="option in operators()" :key="option.id" :value="option.id">
          {{option.name}}
        </option>
      </select>
    </b-col>

    <b-col
      xs="3">
      <label class="sr-only"></label>
      <input v-if="operators(myValue.key)[0].field === 'text'" type="text" class="form-control input-sm" v-model="myValue.value" placeholder="">
      <b-form-group
        v-if="operators(myValue.key)[0].field === 'date'">
        <flat-pickr
          v-model="myValue.value"
          class="form-control"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="danger"
        class="btn-icon rounded-circle"
        v-b-tooltip.hover.right="'Remove'"
        @click.prevent="deleteSelf()">
        <feather-icon
          icon="TrashIcon"
        />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BRow, BCol, VBTooltip, BInputGroup, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'


export default {
  name: 'rule',
  props: ['options', 'myValue'],
  components: {
    BButton,
    BRow,
    BCol,
    vSelect,
    flatPickr,
    BInputGroup,
    BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  watch: {
    'options.keys.options' () {
      this.myValue.key = -99;
    },
    'options.conditions.options' () {
      this.condition = -99;
    },
    key: function (value) {
      this.$emit('signal', value)
    },
    operator: function (value) {
      this.$emit('signal', value)
    },
    value: function (value) {
      this.$emit('signal', value)
    }
  },
  data () {
    return {
      key: '',
      operator: 0,
      value: ''
    }
  },
  model: {
    prop: 'myValue',
    event: 'signal'
  },
  methods: {
    deleteSelf () {
      this.$emit('delete-rule');
    },
    queryFormStatus () {
      return {
        'key': this.myValue.key,
        'operator': this.myValue.operator,
        'value': this.myValue.value
      }
    },
    operators (key=this.myValue.key) {
      var data = []
      var date = [
        {
          name: 'Choose Operator',
          field: 'date',
          id: 0
        },
        {
          name: 'equals',
          field: 'date',
          id: '='
        },
        {
          name: 'greater than',
          field: 'date',
          id: '__gt='
        },
        {
          name: 'greater than or equal',
          field: 'date',
          id: '__gte='
        },
        {
          name: 'less than',
          field: 'date',
          id: '__lt='
        },
        {
          name: 'less than or equal ',
          field: 'date',
          id: '__lte='
        }
      ]
      var numeric = [
        {
          name: 'Choose Operator',
          field: 'text',
          id: 0
        },
        {
          name: 'equals',
          field: 'text',
          id: '='
        },
        {
          name: 'greater than',
          field: 'text',
          id: '__gt='
        },
        {
          name: 'greater than or equal',
          field: 'text',
          id: '__gte='
        },
        {
          name: 'less than',
          field: 'text',
          id: '__lt='
        },
        {
          name: 'less than or equal',
          field: 'text',
          id: '__lte='
        }
      ]
      var text = [
        {
          name: 'Choose Operator',
          field: 'text',
          id: 0
        },
        {
          name: 'equals',
          field: 'text',
          id: '='
        },
        {
          name: 'contains',
          field: 'text',
          id: '__icontains='
        }
      ]
      switch(key) {
        case 'recruiter.name':
          data = text
          break
        case 'manager.name':
          data = text
          break
        case 'candidate.name':
          data = text
          break
        case 'customer.name':
          data = text
          break
        case 'job.name':
          data = text
          break
        case 'sent_date':
          data = date
          break
        case 'days_in_process':
          data = numeric
          break
        case 'last_update':
          data = date
          break
        case 'days_in_stage':
          data = numeric
          break
        case 'status.description':
          data = text
          break
        case 'current_stage.description':
          data = text
          break
        case 'next_stage.description':
          data = text
          break
        case 'process_stage':
          data = numeric
          break
        case 'start_date':
          data = date
          break
        case 'salary':
          data = numeric
          break
        case 'department.description':
          data = text
          break
        case 'fee_rate':
          data = numeric
          break
        case 'revenue':
          data = numeric
          break
        case 'project_revenue':
          data = numeric
          break
        case 'roi':
          data = numeric
          break
        case 'roi_percentage':
          data = numeric
          break
        case 'pricelist_roi':
          data = numeric
          break
        case 'pricelist_roi_percentage':
          data = numeric
          break
        case 'end_date':
          data = numeric
          break
        default:
          data = [
            {
              name: 'Choose Operator',
              field: 'text',
              id: ''
            },
          ]
          break
      }
      return data
    },
    fillRuleStatus (data) {
      this.key = data.key;
      this.operator = data.operator
      this.value = data.value;
    }
  }
}
</script>
  
<style>
.and-or-rule {
  position: relative;
  height: 30px;
  margin-left: 15px !important;
  padding-left: 0;
}
.and-or-rule:before,
.and-or-rule:after {
  content: '';
  position: absolute;
  left: -1px;
  width: 16px;
  height: calc(50% + 15px);
  border-color: #c0c5e2;
  border-style: solid;
}
.and-or-rule:before {
  top: -15px;
  border-width: 0 0 2px 2px;
}
.and-or-rule:after {
  top: 50%;
  border-width: 0 0 0 2px;
}
.and-or-rule:last-child:after {
  border: none;
}
</style>