<template>
  <b-row>
    <b-col cols="12">
      <rule
        class="mt-10"
        v-for="(rule, index) in value" ref="rules"
        v-model="value[index]"
        :options="options" :key="index" @delete-rule="deleteRule(index)">
      </rule>
    </b-col>
    <b-col cols="12">
      <b-button 
        v-if="!isFirst"
        variant="outline-primary"
        class="btn btn-xs btn-purple pull-right"
        @click.prevent="deleteSelf()">
        <feather-icon
          icon="XIcon"
          class="mr-50"
        />
      </b-button>
      <b-button
        variant="outline-primary"
        class="btn btn-xs btn-purple add-rule pull-right float-right mt-1 margin-left-10"
        @click.prevent="addRule">
        <feather-icon
          icon="PlusIcon"
          class="mr-right-3"
        /> Add
      </b-button>
      <b-button
        variant="flat-dark"
        class="btn btn-xs btn-purple add-rule pull-right float-right mt-1 padding-11"
        @click.prevent="resetComponent()">  
        <feather-icon
          icon="RotateCwIcon"
          class="mr-right-3"
        /> Reset to default 
      </b-button>
    </b-col>
    <and-or
      class="and-or-offset col-xs-11"
      v-for="(group, index) in groups" ref="groups"
      :options="options" :key="group" @delete-group="deleteGroup(index)">
    </and-or>
    <!-- <div class="and-or-template col-xs-12" :class="isFirst ? 'and-or-first' : '' ">
      <div class="form-group and-or-top col-xs-12">
        <div class="col-xs-5" style="padding: 0">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :class=" isAnd ? 'btn-primary-outline-focus' : '' "
            pill
            @click.prevent="clickAnd">
            &nbsp;And&nbsp;
          </b-button>
          <b-button 
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :class=" !isAnd ? 'btn-primary-outline-focus' : '' "
            pill
            @click.prevent="clickOr">
            &nbsp;Or&nbsp;
          </b-button>
        </div>

        <div class="col-xs-7 btn-and-or">
          <b-button v-if="!isFirst" class="btn btn-xs btn-purple pull-right" @click.prevent="deleteSelf()">
            <i class="fa fa-fw fa-close"></i>
          </b-button>
          <b-button class="btn btn-xs btn-purple pull-right" @click.prevent="addGroup"> + ( group ) </b-button>
          <b-button class="btn btn-xs btn-purple add-rule pull-right" @click.prevent="addRule"> + add </b-button>
        </div>
      </div>

      <rule
        v-for="(rule, index) in rules" ref="rules"
        :options="options" :key="rule" @delete-rule="deleteRule(index)">
      </rule>

      <and-or
        class="and-or-offset col-xs-11"
        v-for="(group, index) in groups" ref="groups"
        :options="options" :key="group" @delete-group="deleteGroup(index)">
      </and-or>
    </div> -->
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import Rule from './Rule'
import {
  BButton, BRow, BCol
} from 'bootstrap-vue'

export default {
  name: 'andOr',
  components: {
    Rule,
    BButton,
    BRow,
    BCol
  },
  directives: {
    Ripple
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: []
    }
  },
  model: {
    prop: 'value',
    event: 'signal'
  },
  created () {
  },
  data () {
    return {
      isAnd: true,
      groups: [],
      rules: []
    }
  },
  watch: {
    value: function (value) {
      if (this.rules === []) {
        this.rules = this.value
      }
    }
  },
  methods: {
    resetComponent () {
      this.$emit('reset', true)
      this.groups = []
      this.rules = []
      // this.value = []
      // this.addRule()
    },
    change () {
      this.$emit('signal', this.queryFormStatus())
    },
    clickAnd () {
      this.isAnd = true;
    },
    clickOr () {
      this.isAnd = false;
    },
    addRule () {
      var id = this.generateId();
      this.rules.push(id);
    },
    addGroup () {
      var id = this.generateId();
      this.groups.push(id);
    },
    deleteSelf () {
      this.$emit('delete-group');
    },
    deleteRule (index) {
      this.value.splice(index, 1);
    },
    deleteGroup (index) {
      this.groups.splice(index, 1);
    },
    queryFormStatus () {
      var query = {};
      var rules = this.$refs.rules || {};
      var groups = this.$refs.groups || {};
      var i, j;
      query['condition'] = this.isAnd ? 'AND' : 'OR';
      query['rules'] = [];
      for(i = 0; i < rules.length; i++){
        query.rules.push(rules[i].queryFormStatus ());
      }
      for(j = 0; j < groups.length; j++){
        query.rules[query.rules.length] = groups[j].queryFormStatus();
      }
      return query;
    },
    fillFormStatus (data) {
      var i, len;
      var group = this;
      group.rules = [];
      group.groups = [];
      if(data){
        group.isAnd = /and/i.test(data.condition);
        len = data.rules.length;
        for(i = 0; i < len; i++){
          if(data.rules[i].condition){
            group.groups.push(group.generateId());
            (function (i, index) {
              group.$nextTick(function () {
                group.$refs.groups[index].fillFormStatus(data.rules[i]);
              });
            })(i, group.groups.length - 1);
          }
          else {
            group.rules.push(group.generateId());
            (function (i, index) {
              group.$nextTick(function () {
                group.$refs.rules[index].fillRuleStatus(data.rules[i]);
              });
            })(i, group.rules.length - 1);
          }
        }
      }
    },
    generateId () {
      this.value.push({
        key: '',
        operator: 0,
        value: ''
      })
      return 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
      });
    }
  }
}
</script>

<style>
.and-or-template {
  padding: 8px;
  position: relative;
  border-radius: 3px;
  border: 1px solid #6d77b8;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  /* width: 100%; */
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  border-top-color: #6d77b8;
  background-color: rgba(255, 255, 255, 0.9);
}
.and-or-template:before,
.and-or-template:after {
  content: '';
  position: absolute;
  left: -17px;
  width: 16px;
  height: calc(50% + 18px);
  border-color: #c0c5e2;
  border-style: solid;
}
.and-or-template:before {
  top: -18px;
  border-width: 0 0 2px 2px;
}
.and-or-template:after {
  top: 50%;
  border-width: 0 0 0 2px;
}
.and-or-first:before,
.and-or-first:after,
.and-or-template:last-child:after {
  border: none;
}
.and-or-top,
.btn-and-or {
  padding: 0;
}
.btn-and-or button {
  margin-left: 4px;
}
.and-or-offset {
  margin-left: 30px;
}
</style>